export type OrumEnvironment = 'local' | 'dev' | 'uat' | 'stage' | 'prod'

export function getOrumEnvironment(hostname: string): OrumEnvironment {
  if (/localhost/.test(hostname) || /127\.0\.0\.1/.test(hostname)) {
    return 'local'
  } else if (/-dev/.test(hostname)) {
    return 'dev'
  } else if (/-uat/.test(hostname)) {
    return 'uat'
  } else if (/-sandbox/.test(hostname)) {
    return 'stage'
  } else {
    return 'prod'
  }
}

export function getOrumAPIUrl(env: OrumEnvironment) {
  switch (env) {
    case 'prod':
      return 'https://api.orum.io'
    case 'stage':
      return 'https://api-sandbox.orum.io'
    case 'local':
      // only UAT is currently able to generate signatures and send email, so we need to use UAT for local development
      return 'https://api-uat.orum.io'
    default:
      return `https://api-${env}.orum.io`
  }
}
