import { getStaticAssets } from '@/lib/staticAssets'

export default function ExampleTransaction() {
  const staticAsset = getStaticAssets(window.location.hostname)
  return (
    <img
      src={`${staticAsset('example-transaction.png')}`}
      alt="Bank transaction titled ACCTVERIFY-4443 for $0.01"
      className="h-[141px]"
    />
  )
}
