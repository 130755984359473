import { FormattedMessage } from 'react-intl'
import orumLogo from '@/assets/orum-logo-dark.svg'

export default function OrumLogo() {
  return (
    <div className="text-center">
      <a href="https://orum.io" className="inline-block">
        <div className="text-[8px]">
          <FormattedMessage id="poweredBy" />
        </div>
        <img src={orumLogo} className="m-auto mt-1 w-20" alt="Orum" />
      </a>
    </div>
  )
}
