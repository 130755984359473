import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import useControlParams from '@/hooks/useControlParams'

export default function Error() {
  const { enterpriseName } = useControlParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (!enterpriseName) {
      navigate(`/`)
      return
    }
  }, [enterpriseName])

  return (
    <div className="flex flex-col text-center items-center">
      <h1 className="font-bold text-4xl px-2 tracking-tight text-center text-gray-900">
        <FormattedMessage id="expiredPageHeader" />
      </h1>
      <p className="text-gray-500 mt-5">
        <FormattedMessage
          id="expiredPageMessage"
          values={{ enterprise: enterpriseName }}
        />
      </p>
    </div>
  )
}
