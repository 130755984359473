// utilities for getting urls to assets uploaded to s3 outside our normal vite build,
// For example, `example-transaction.png` is uploaded directly to the bucket via
// terraform.

import { getOrumEnvironment } from '@/lib/env'

export function getStaticAssets(hostname: string): (path: string) => string {
  return (path) => {
    const env = getOrumEnvironment(hostname)
    switch (env) {
      case 'prod':
        return `https://verify.orum.io/${path}`
      case 'stage':
        return `https://verify-sandbox.orum.io/${path}`
      case 'local':
        return `https://verify-dev.orum.io/${path}`
      default:
        return `https://verify-${env}.orum.io/${path}`
    }
  }
}
