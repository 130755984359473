import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import ExampleTransaction from '@/components/ExampleTransaction'
import useControlParams from '@/hooks/useControlParams'
import { getOrumAPIUrl, getOrumEnvironment } from '@/lib/env'
import Form from '@/pages/verify/Form'
import NeedHelpModal from '@/pages/verify/NeedHelpModal'

const maxAttempts = 3
const orumVersion = 'v2022-09-21'
const env = getOrumEnvironment(location.hostname)
const baseURL = getOrumAPIUrl(env)

export default function Verify() {
  const intl = useIntl()
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showHelp, setShowHelp] = useState<boolean>(false)
  const { accountId, enterpriseName, signature, expiresAt } = useControlParams()

  useEffect(() => {
    if (!enterpriseName) {
      navigate(`/`)
      return
    }

    if (!accountId || !signature || !expiresAt) {
      navigate(`/${enterpriseName}`)
      return
    }

    const expirationDate = new Date(expiresAt)

    if (!isValidDate(expirationDate)) {
      navigate(`/${enterpriseName}`)
      return
    }

    if (isExpired(expirationDate)) {
      navigate(`/${enterpriseName}/expired`)
    }
  }, [enterpriseName, signature, expiresAt, accountId])

  const [attemptsRemaining, setAttemptsRemaining] =
    useState<number>(maxAttempts)

  const handleSubmit = async (code: string) => {
    if (isSubmitting) {
      return
    }

    setIsSubmitting(true)

    const qs = new URLSearchParams()
    qs.append('signature', signature!)
    qs.append('expires_at', new Date(expiresAt!).toISOString())

    try {
      const res = await fetch(
        `${baseURL}/verify/accounts/${encodeURIComponent(
          accountId!,
        )}/control?${qs.toString()}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Orum-Version': orumVersion,
            'x-orum-request-origin': 'verify-control-ui',
          },
          body: JSON.stringify({
            statement_code: code,
          }),
        },
      )

      if (res.status >= 400 && res.status < 500) {
        const errorResponse = await res.json()

        if (errorResponse.error_code === 'invalid_statement_code') {
          const attempts = errorResponse.attempts_remaining
            ? errorResponse.attempts_remaining
            : attemptsRemaining - 1
          setAttemptsRemaining(attempts)
          setIsSubmitting(false)
          return
        }

        if (errorResponse.error_code === 'too_many_requests') {
          setAttemptsRemaining(0)
          setIsSubmitting(false)
          return
        }
      }

      if (res.status !== 200) {
        navigate(`/${enterpriseName}/error`)
        return
      }

      navigate(`/${enterpriseName}/${accountId}/verified`)
    } catch (err) {
      navigate(`/${enterpriseName}/error`)
    }
  }

  const buttonTextId = attemptsRemaining < maxAttempts ? 'tryAgain' : 'continue'
  const buttonText = intl.formatMessage({ id: buttonTextId })

  const messageSupport = (
    <div className="mt-4 text-gray-500">
      <p className="text-center px-5">
        <FormattedMessage
          id="messageSupport"
          values={{ enterprise: enterpriseName }}
        />
      </p>
    </div>
  )

  const form = (
    <>
      <div className="mt-3">
        <Form
          buttonText={buttonText}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </div>

      <button
        className="mt-6 text-center block m-auto text-red-400 hover:pointer"
        onClick={() => setShowHelp(true)}
      >
        <FormattedMessage id="needHelpLink" />
      </button>

      <NeedHelpModal isOpen={showHelp} onClose={() => setShowHelp(false)} />
    </>
  )

  return (
    <>
      <h1 className="font-bold text-4xl px-2 tracking-tight text-center text-gray-900">
        <FormattedMessage
          id={'headerAttemptsRemaining' + attemptsRemaining.toString()}
        />
      </h1>
      <div className="mt-4 text-gray-500">
        <p className="text-center px-5">
          <FormattedMessage
            id={'messageAttemptsRemaining' + attemptsRemaining.toString()}
          />
        </p>
      </div>

      {attemptsRemaining <= 0 ? messageSupport : null}

      <div className="mt-3 p-5">
        <ExampleTransaction />
      </div>

      {attemptsRemaining > 0 ? form : null}
    </>
  )
}

function isValidDate(date: Date): boolean {
  return !isNaN(date.getTime())
}

function isExpired(date: Date): boolean {
  return date < new Date()
}
