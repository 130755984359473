import { FormattedMessage } from 'react-intl'
import verified from '@/assets/verified.svg'

export default function Verified() {
  return (
    <div className="flex flex-col text-center items-center">
      <h1 className="font-bold text-4xl px-2 tracking-tight text-center text-gray-900">
        <FormattedMessage id="successHeader" />
      </h1>
      <p className="text-gray-500 mt-5">
        <FormattedMessage id="successMessage" />
      </p>
      <img src={verified} className="w-14 mt-10" alt="Success" />
    </div>
  )
}
