import { Navigate, Route, Routes } from 'react-router-dom'
import OrumLogo from '@/components/OrumLogo'
import Error from '@/pages/error/Error'
import Expired from '@/pages/expired/Expired'
import Home from '@/pages/home/Home'
import Verified from '@/pages/verified/Verified'
import Verify from '@/pages/verify/Verify'

export default function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="mt-10 flex-grow w-[375px] m-auto">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:enterpriseName" element={<Home />} />
          <Route path="/:enterpriseName/error" element={<Error />} />
          <Route path="/:enterpriseName/expired" element={<Expired />} />
          <Route path="/:enterpriseName/:accountId" element={<Verify />} />
          <Route
            path="/:enterpriseName/:accountId/verified"
            element={<Verified />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <div className="mt-10 mb-5">
        <OrumLogo />
      </div>
    </div>
  )
}
