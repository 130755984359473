import { MouseEventHandler, ReactNode } from 'react'
import { PulseLoader } from 'react-spinners'

type Props = {
  children: ReactNode
  type?: 'submit' | 'reset' | 'button'
  onClick?: MouseEventHandler<HTMLButtonElement>
  isLoading?: boolean
}

export function Button({
  onClick = () => null,
  type = 'button',
  children,
  isLoading,
}: Props) {
  return (
    <button
      type={type}
      onClick={onClick}
      className="mt-3 w-full items-center justify-center rounded-md bg-red-400 px-3 py-2 text-sm text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 sm:w-full"
      disabled={isLoading}
    >
      {isLoading ? <PulseLoader size={6} color="white" /> : children}
    </button>
  )
}
