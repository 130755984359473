import { useParams, useSearchParams } from 'react-router-dom'

export default () => {
  const { accountId, enterpriseName } = useParams()
  const [queryParams] = useSearchParams()

  // queryParams.get() can return null, the following converts to undefined for consistency with useParams
  const signature = queryParams.get('signature') || undefined
  const expiresAt = queryParams.get('expires_at') || undefined

  return {
    accountId,
    enterpriseName,
    signature,
    expiresAt,
  }
}
