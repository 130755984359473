export default {
  headerAttemptsRemaining3: 'Verify your bank account instantly',
  headerAttemptsRemaining2: 'Please try again',
  headerAttemptsRemaining1: 'Final attempt',
  headerAttemptsRemaining0: 'Codes failed',
  messageAttemptsRemaining3:
    "Find the $0.01 deposit in the bank account you're verifying. The code you need is the last 4 digits after the dash. It looks like this:",
  messageAttemptsRemaining2:
    'The 4-digit code you entered is not accurate. You have two more attempts. Check your bank account to find the 4-digit code. The code you need is the last four digits after the dash. It looks like this:',
  messageAttemptsRemaining1:
    'The 4-digit code you entered is not accurate. You have one more attempt. Check your bank account to find the 4-digit code. The code you need is the last four digits after the dash. It looks like this:',
  messageAttemptsRemaining0:
    'The code you entered is not accurate and you are out of attempts.',
  messageSupport: 'Please reach out to {enterprise} for help.',
  codePlaceholder: 'Enter 4-digit code',
  yourCode: 'Your code',
  expiredPageHeader: 'Code expired',
  expiredPageMessage:
    'The code you were sent has expired. Request a new one and try again or reach out to {enterprise} for help.',
  errorPageHeader: 'Something went wrong',
  errorPageMessage:
    'Please repeat the instructions in the email we sent. If the problem persists, reach out to {enterprise} for help.',
  formErrorLength: 'Code should be 4 characters long',
  formErrorNoCode: 'Please enter the code on your bank statement',
  poweredBy: 'Powered by',
  continue: 'Continue',
  tryAgain: 'Try again',
  successHeader: 'Success',
  successMessage: 'Your account was verified!',
  checkEmailHeader: 'Check your email',
  checkEmailMessage:
    'Are you looking to verify your bank account? Check your inbox and follow the instructions in the email we sent.',
  checkEmailMessageWithEnterprise:
    'Are you looking to verify your bank account with {enterprise}? Check your inbox and follow the instructions in the email we sent.',
  noEmail: "Don't see an email? Reach out to {enterprise} for help.",
  needHelpLink: 'Need help?',
  needHelpHeader: 'Need help?',
  needHelpMessage:
    'Most verification codes take up to 15 seconds to appear in your bank account but some can take longer. If it has been over a minute, check back at this time on the next business day.',
  done: 'Done',
}
