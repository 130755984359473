import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '@/components/Button'

type Inputs = {
  code: string
}

type Props = {
  isSubmitting: boolean
  onSubmit: (code: string) => void
  buttonText: string
}

export default function Form({ isSubmitting, onSubmit, buttonText }: Props) {
  const intl = useIntl()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  return (
    <form
      className="px-2"
      onSubmit={handleSubmit(({ code }: Inputs) => onSubmit(code))}
    >
      <div className="">
        <div>
          <input
            className="block p-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 focus-visible:outline-red-600 sm:text-sm sm:leading-6"
            placeholder={intl.formatMessage({ id: 'codePlaceholder' })}
            {...register('code', {
              required: intl.formatMessage({ id: 'formErrorNoCode' }),
              minLength: {
                value: 4,
                message: intl.formatMessage({ id: 'formErrorLength' }),
              },
              maxLength: {
                value: 4,
                message: intl.formatMessage({ id: 'formErrorLength' }),
              },
            })}
          />
        </div>
        {errors.code && (
          <p className="mt-2 text-xs text-red-600">{errors.code.message}</p>
        )}
      </div>
      <Button type="submit" isLoading={isSubmitting}>
        {buttonText}
      </Button>
    </form>
  )
}
