import ReactDOM from 'react-dom/client'
import '@/index.css'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { BrowserRouter } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'
import App from '@/App'
import translations from '@/i18n/en'
import { getOrumEnvironment } from '@/lib/env'

const DATADOG_APP_ID = '4783bc91-dddd-456f-bbca-1989e87c4586'
const DATADOG_CLIENT_TOKEN = 'pubc4d46943e175cef13513fb40fdad09bd'
const env = getOrumEnvironment(location.hostname)

datadogRum.init({
  applicationId: DATADOG_APP_ID,
  clientToken: DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'hamlet',
  env,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [/https:\/\/.*\.orum\.io/],
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <IntlProvider locale="en" messages={translations}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </IntlProvider>
  </React.StrictMode>,
)
