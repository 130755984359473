import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Dialog, Transition } from '@headlessui/react'
import { Button } from '@/components/Button'

interface BaseModalProps {
  isOpen: boolean
  onClose: () => void
}

export default function NeedHelpModal({ isOpen, onClose }: BaseModalProps) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                data-testid="modal-dialog-panel"
                className="relative transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8  sm:p-6 bg-gray-50 w-full max-w-md sm:mx-20"
              >
                <div className="flex items-start">
                  <div className="mt-3 flex-1 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-center text-xl leading-6 text-gray-900"
                    >
                      <FormattedMessage id="needHelpHeader" />
                    </Dialog.Title>

                    <div className="mt-4 mb-4 text-gray-500">
                      <FormattedMessage id="needHelpMessage" />
                    </div>

                    <Button onClick={onClose}>
                      <FormattedMessage id="done" />
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
