import { FormattedMessage } from 'react-intl'
import useControlParams from '@/hooks/useControlParams'

export default function Home() {
  const { enterpriseName } = useControlParams()

  const message = enterpriseName ? (
    <>
      <p className="text-center px-5 mt-10">
        <FormattedMessage
          id="checkEmailMessageWithEnterprise"
          values={{ enterprise: enterpriseName }}
        />
      </p>
      <p className="text-center px-5 mt-5">
        <FormattedMessage
          id="noEmail"
          values={{ enterprise: enterpriseName }}
        />
      </p>
    </>
  ) : (
    <>
      <p className="text-center px-5 mt-10">
        <FormattedMessage id="checkEmailMessage" />
      </p>
    </>
  )

  return (
    <div className="w-[375px] m-auto">
      <h1 className="font-bold text-4xl px-2 tracking-tight text-center text-gray-900">
        <FormattedMessage id="checkEmailHeader" />
      </h1>
      {message}
    </div>
  )
}
